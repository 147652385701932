:root {
  --font-family-base: 'Myriad', sans-serif;
  --font-family-mono: 'IBM Plex Mono', monospace;

  /* Used weights */
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-600: 600;
  --font-weight-800: 800;

  --letter-spacing-text: 0.01em;

  --line-height-heading-alt: 0.9;
  --line-height-heading: 1.1;
  --line-height-text-sm: 1.4;
  --line-height-text: 1.6;

  /* Sizes */
  --font-size-unit: 1px;
  --font-size-viewport-min: 480;
  --font-size-viewport-max: 1920;
  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc((var(--font-size-growth) * 100vw) - (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit)));

  --font-size-10: 10px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-19: 19px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-30: 30px;
  --font-size-32: 32px;
  --font-size-36: 36px;
  --font-size-40: 40px;
  --font-size-48: 48px;
  --font-size-60: 60px;
  --font-size-72: 72px;
  --font-size-132: 132px;

  --font-size-12-14: clamp(12px, calc(12px + 2 * var(--m)), 14px);
  --font-size-16-18: clamp(16px, calc(16px + 2 * var(--m)), 18px);
  --font-size-18-20: clamp(18px, calc(18px + 2 * var(--m)), 20px);
  --font-size-18-22: clamp(18px, calc(18px + 4 * var(--m)), 22px);
  --font-size-22-25: clamp(22px, calc(22px + 3 * var(--m)), 25px);
  --font-size-22-32: clamp(22px, calc(22px + 10 * var(--m)), 32px);
  --font-size-24-28: clamp(24px, calc(24px + 4 * var(--m)), 28px);
  --font-size-32-22: clamp(32px, calc(32px - 10 * var(--m)), 22px);
  --font-size-28-32: clamp(28px, calc(28px + 4 * var(--m)), 32px);
  --font-size-32-40: clamp(32px, calc(32px + 8 * var(--m)), 40px);
  --font-size-32-48: clamp(32px, calc(32px + 16 * var(--m)), 48px);
  --font-size-32-64: clamp(32px, calc(32px + 32 * var(--m)), 64px);
  --font-size-48-64: clamp(48px, calc(48px + 16 * var(--m)), 64px);
  --font-size-36-72: clamp(36px, calc(36px + 36 * var(--m)), 72px);
  --font-size-40-45: clamp(40px, calc(40px + 5 * var(--m)), 45px);
  --font-size-48-102: clamp(48px, calc(48px + 84 * var(--m)), 102px);
  --font-size-48-132: clamp(48px, calc(48px + 84 * var(--m)), 132px);
  --font-size-132-220: clamp(132px, calc(132px + 88 * var(--m)), 220px);
}
