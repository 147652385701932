:export {
  colorBlack: #000;
  colorGray200: #ededee;
  colorBlue300: #0089ff;
  colorBlue500: #0058ff;
  colorOrange500: #fa6400;
  colorOrange700: #e03000;
  colorOrange300: #ffbc1f;
  colorGreen700: #009354;
}

:root {
  --color-black: #000;
  --color-black--10: rgba(0, 0, 0, 0.1);
  --color-black--20: rgba(0, 0, 0, 0.2);
  --color-black--30: rgba(0, 0, 0, 0.3);
  --color-black--50: rgba(0, 0, 0, 0.5);
  --color-black--70: rgba(0, 0, 0, 0.7);

  --color-white: #fff;
  --color-white--10: rgba(255, 255, 255, 0.1);
  --color-white--15: rgba(255, 255, 255, 0.15);
  --color-white--20: rgba(255, 255, 255, 0.2);
  --color-white--40: rgba(255, 255, 255, 0.4);
  --color-white--80: rgba(255, 255, 255, 0.8);

  --color-gray-900: #202122;
  --color-gray-900--25: rgba(32, 33, 34, 0.25);
  --color-gray-900--80: rgba(32, 33, 34, 0.8);
  --color-gray-800: #2d2d2d;
  --color-gray-600: #75757a;
  --color-gray-500: #9c9ea0;
  --color-gray-400: #bbbcbe;
  --color-gray-300: #e0e0e1;
  --color-gray-200: #ededee;
  --color-gray-100: #f5f5f5;
  --color-gray-100--80: rgb(245, 245, 245, 0.8);

  --color-blue-900: #000060;
  --color-blue-700: #000faa;
  --color-blue-500: #0058ff;
  --color-blue-300: #0089ff;
  --color-blue-100: #58c9ff;

  --color-blue-light-100: #e5eeff;

  --color-orange-900: #801604;
  --color-orange-700: #e03000;
  --color-orange-500: #fa6400;
  --color-orange-400: #ff8533;
  --color-orange-300: #ffbc1f;
  --color-orange-100: #ffd983;

  --color-green-900: #006e46;
  --color-green-700: #009354;
  --color-green-500: #00b269;
  --color-green-300: #00cd87;
  --color-green-100: #55e6b4;

  --color-black-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
}
