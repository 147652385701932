:root {
  --size-4: 4px;
  --size-8: 8px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-32: 32px;
  --size-36: 36px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-64: 64px;
  --size-80: 80px;
  --size-96: 96px;
  --size-128: 128px;
  --size-164: 164px;

  --size-blur-10: 10px;

  --menu-padding-sm: calc(var(--size-24) * 2 + 62px);
  --menu-padding: calc(var(--size-48) * 2 + 62px);
}
