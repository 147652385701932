:root {
  --duration-150: 150ms;
  --duration-200: 200ms;
  --duration-450: 450ms;
  --duration-600: 600ms;
  --duration-1200: 1200ms;

  --ease: ease;
  --ease-in-out: ease-in-out;
  --ease-out-sine: cubic-bezier(0.61, 1, 0.88, 1);
  --ease-in-sine: cubic-bezier(0.12, 0, 0.39, 0);
}
